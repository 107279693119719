import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import * as React from 'react';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import axios from 'axios';



// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// import { getAllCustomers, getAllProjectbyCustomerId,getAllPricings,getAllInvoices } from '../services/apihandler';
// import InvoiceCard from './Invoice/Invoicecard';
import InvoiceCard from "./invoicecard"
import InvoiceNote from './invoicenote';


//--------------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '10%',
  left: '62%',
  transform: 'translate(-50%, 0%)',
  width: '79%',
  bgcolor: 'background.paper',
  outline: 'none',
  borderRadius: '15px',

  // border: '2px solid blue',
  // boxShadow: 24,
  p: 4,
};



function AddInvoice() {
  const [price, setPrice] = useState([]);
  const [state, setState] = React.useState('');
  const [user, setUser] = useState({});

  const handleChangev1 = async (event) => {
    setCustomer(event.target.value);
    console.log(event.target.value, "----bhabesh")
    const data = await axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/client/getclient/${event.target.value}`, {
      headers: {
        'auth-token': token
      }
    });
    const filteredData = customerdata.find(elem => elem?._id === event.target.value);
    console.log(filteredData, '------------------\\\\');
    console.log(data.data.data, "data---")
    setUser(data.data.data);
    // setProject()
  };


  console.log(state);
  const [project, setProject] = React.useState('')
  const handleChangev2 = (event) => {
    setProject(event.target.value);
  };


  const [recurringinvoice, setRecurringInvoice] = React.useState('');
  const handleChangev3 = (event) => {
    setRecurringInvoice(event.target.value);
    console.log(recurringinvoice);
  };
  const [discount, setDiscount] = React.useState('');
  const handleChangev4 = (event) => {
    const newdicount = event.target.value;
    setDiscount(newdicount);
  };

  const [product, setProduct] = React.useState('')
  const handleChangev5 = (event) => {
    setProduct(event.target.value);
    const filterProduct = price.find(eachproduct => eachproduct?.name === event.target.value);
    console.log("product=======", filterProduct);

    setInput({ ...input, itemName: filterProduct?.name, itemDescription: filterProduct?.description, quantity: filterProduct?.quantity, tax: filterProduct?.tax, rate: filterProduct?.price })

  };



  // --------------------------------------API Handle----------->
  const [customerdata, setCustomerdata] = useState([]);
  const [customer, setCustomer] = useState("")
  const token = localStorage.getItem('auth_token');

  useEffect(() => {
    getCaustomer(); // This will log the updated value
  }, []);


  const getCaustomer = async () => {
    try {
      const data = await axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/client/getclient`, {
        headers: {
          'auth-token': token
        }
      });
      console.log(data);
      setCustomerdata(data.data.data);

    } catch (error) {

    }
  }


  console.log("searchhhhhhhhhhhhhhh", customerdata);

  const [projectdata, setProjectData] = useState([]);

  //   useEffect(() => {
  //     projectHandler(state);
  //   }, [state])

  //   const projectHandler = async (id) => {
  //     const projects = await getAllProjectbyCustomerId(id);
  //     console.log(projects, 'jjbhjvghvhhhhhhhh');
  //     if (projects.data.statusCode === '200') {
  //       setProjectData(projects.data.projects);
  //     }

  //   }


  console.log(projectdata)

  //   useEffect(()=>{
  //     productApi()
  //   },[])


  //   const productApi = async()=>{
  //     const allproduct = await getAllPricings();
  //     console.log(allproduct,'products')

  //     if(allproduct.status === 200){
  //       setPrice(allproduct.data.pricing);

  //     }

  //   };
  //   console.log(price)

  //   useEffect(()=>{
  //     invoiceApi();
  //   },[])

  const [requInvoice, setRequInvoice] = useState([]);

  //   const invoiceApi = async()=> {
  //       const invoice = await getAllInvoices();
  //       console.log(invoice,'invoice');
  //       if(invoice.status === 200){
  //         setRequInvoice(invoice.data.invoice);
  //       }
  //   }











  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedDueDate, setSelectedDueDate] = React.useState(null);
  const [cards, setCards] = useState([]);
  const [notes, setNotes] = useState([]);

  // --------------------------------------CTRL Z------------------------------------->

  const newCard = {
    itemName: '',
    itemDescription: '',
    quantity: 1,
    rate: 0,
    tax: 0,
    amount: 0
  };

  const newNote = {
    title: '',
    description: ''
  }

  const [noteInput, setNoteInput] = useState(newNote);

  const [input, setInput] = useState(newCard);

  const addCard = () => {
    setCards([...cards, input]);
    setTimeout(() => {
      return setInput(newCard)
    }, 0)

  };

  const handleChangev15 = (event) => {
    const updatedItemName = event.target.value;
    setInput({ ...input, itemName: updatedItemName });

  };

  const handleChangev16 = (event) => {
    setInput({ ...input, itemDescription: event.target.value })
  }


  const handleChangev6 = (event) => {
    setInput({ ...input, quantity: event.target.value });
  };

  const handleChangev7 = (event) => {
    setInput({ ...input, rate: event.target.value });
  };

  const handleChangev8 = (event) => {
    setInput({ ...input, tax: event.target.value });
  }



  // ----------------------------------------------------------------------------------->
  console.log(cards, 'array data')
  const addNotes = () => {
    setNotes([...notes, noteInput])
    setNoteInput(newNote);

  };


  function deleteHandlerCard(index) {
    console.log('Index to delete:', index);

    console.log('Before deletion - cards:', cards);
    const updatedCards = cards.filter((_, i) => i !== index);
    console.log('Updated cards:', updatedCards);

    // Recalculate totals after deletion
    const newTotalRates = calculateTotalRates(updatedCards);
    const newTotalTax = calculateTotalTaxs(updatedCards);

    setCards(updatedCards);
    setTotalRates(newTotalRates);
    setTotalTax(newTotalTax);

    console.log('After deletion - cards:', updatedCards);
  }

  function deleteHandlerNote(index) {
    console.log("index============", index);
    console.log("noters============", notes);
    const updatedNotes = notes.filter((ele, i) => i !== index);
    setNotes(updatedNotes);
  }





  // ---------------------------------------------------Date&&Calender---------------->
  const initialDate = dayjs();
  // const fomatedDate=initialDate.format('DD/MM/YYYY');
  // const formatedDateObj=dayjs(fomatedDate);

  const [invdate, setInvdate] = useState(initialDate);
  const handleChangev9 = (date) => {
    setInvdate(date);
  }

  const dueDate = dayjs(initialDate).add(3, 'day');

  const [duedate, setDuedate] = useState(dueDate);

  const handleChangev10 = (date) => {
    setDuedate(date);

  }




  // ----------------------------------------------------------------------------------->

  const [totalRates, setTotalRates] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const handleCardDataChange = (index, field, value) => {
    // Update the field of the specific card at the given index
    const updatedCards = [...cards];
    updatedCards[index][field] = value;
    // Update the state to reflect the changes in the cards array
    setCards(updatedCards);
    const newTotalRates = calculateTotalRates(updatedCards);
    setTotalRates(newTotalRates);
    const newTotalTax = calculateTotalTaxs(updatedCards);
    setTotalTax(newTotalTax);
  };

  const handleNoteDataChange = (index, field, value) => {
    const updatedNotes = [...notes];
    updatedNotes[index][field] = value;
    setNotes(updatedNotes);
  }

  const [adjustment, setAdjustment] = useState(0);

  const handleChangev11 = (event) => {
    const adjustmnt = event.target.value;
    setAdjustment(Number(adjustmnt));
  }
  console.log(adjustment, typeof (adjustment))


  const calculateTotalRates = (cards) => {
    return cards.reduce((total, card) => total + card.amount, 0);
  };
  const calculateTotalTaxs = (cards) => {
    return cards.reduce((total, card) => total + (card.discnt * card.quantity), 0);
  };




  const [distype, setDistype] = useState('flat');
  const [discont, setDiscont] = useState(0);
  const [inputvalue, setInputvalue] = useState(0)



  const handleChangev13 = (e) => {
    const newdiscont = e.target.value;
    setInputvalue(newdiscont);

    if (distype === 'percentage') {
      const parsedDiscount = parseFloat(newdiscont);
      if (!Number.isNaN(parsedDiscount)) {
        if (discount === 'Before Tax') {
          const percentdiscount = (parseFloat(totalRates) * parsedDiscount) / 100;
          setDiscont(percentdiscount);
        } else if (discount === 'After Tax') {
          const percentsdiscount = (totalRates + totalTax) ? (totalRates + totalTax) * parsedDiscount / 100 : 0;
          setDiscont(percentsdiscount);
        } else {
          setDiscont(parsedDiscount);
        }
      } else {
        setDiscont(0);
      }
    } else {
      setDiscont(parseFloat(newdiscont));
    }
  };



  const handleChangev14 = (e) => {
    const newdistype = e.target.value;
    setDistype(newdistype);

    if (newdistype === 'flat') {
      setDiscont(inputvalue); // Use the current input value for flat
    } else if (newdistype === 'percentage') {
      // Convert the current input value to a percentage value
      const parsedInputValue = parseFloat(inputvalue);
      if (!Number.isNaN(parsedInputValue)) {
        if (discount === 'Before Tax') {
          const percentValue = (parsedInputValue * totalRates) / 100;
          setDiscont(percentValue);
        } else if (discount === 'After Tax') {
          const percentValue = (totalRates + totalTax) ? (parsedInputValue * (totalRates + totalTax)) / 100 : 0;
          setDiscont(percentValue);
        } else {
          setDiscont(0);
        }
      } else {
        setDiscont(0);
      }
    }
  };


  const calculateDiscount = (newdiscont, taxMode, totalRates, totalTax, distype) => {
    const parsedDiscount = parseFloat(newdiscont);

    if (!Number.isNaN(parsedDiscount)) {
      if (taxMode === 'Before Tax') {
        if (distype === 'flat') {
          return parsedDiscount;
        }
        if (distype === 'percentage') {
          return (totalRates * parsedDiscount) / 100;
        }
      }

      if (taxMode === 'After Tax') {
        if (distype === 'flat') {
          return parsedDiscount;
        }
        if (distype === 'percentage') {
          // Ensure totalTax is added only once in the percentage calculation
          return ((totalRates + totalTax) !== 0) ? ((totalRates + totalTax) * parsedDiscount) / 100 : 0;

        }
      }
    }

    return 0;
  };

  useEffect(() => {
    console.log('totalRates', totalRates);
    console.log('totalTax', totalTax);
    console.log('inputvalue', inputvalue);
    console.log('taxMode', discount);
    console.log('distype', distype);
    // Calculate the discount based on the selected tax mode and input value
    const newDiscount = calculateDiscount(inputvalue, discount, totalRates, totalTax, distype);
    setDiscont(newDiscount);
  }, [totalRates, totalTax, inputvalue, discount, distype]);


  // useEffect((index,field,value)=>{
  //   if(handleCardDataChange){
  //    handleCardDataChange(index,field,value)

  //   }
  // },[input])














  return (
    <div>
      <Box sx={style} >
        <div>
          <div>
            <span style={{ fontWeight: 'bold', fontSize: '20px' }}>Add New Invoice</span>
          </div><hr />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <div>
              <FormControl sx={{ mt: 1, width: '230px' }} >
                <InputLabel id="demo-select-small-label">Customer</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={customer}
                  label="Customer"
                  onChange={handleChangev1}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                >
                  <MenuItem value={''}>None</MenuItem>
                  {customerdata.map((elem, ind) => {
                    if (elem && elem.name) {
                      return (
                        <MenuItem key={ind} value={elem._id}>{elem.name}</MenuItem>
                      );
                    }
                    return null;
                  })}
                </Select>
              </FormControl>
            </div>

            <div>
              <FormControl sx={{ mt: 1, width: '230px' }} >
                <InputLabel id="demo-select-small-label">Currency</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={project}
                  label="Currency"
                  onChange={handleChangev2}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="inr">INR</MenuItem>
                  <MenuItem value="usd">USD</MenuItem>




                </Select>
              </FormControl>
            </div>
            <div style={{ width: '230px' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}  >
                  <DatePicker label='Invoice Date' value={invdate} onChange={(date) => handleChangev9(date)} />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div style={{ width: '230px' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>

                <DemoContainer components={['DatePicker']} >
                  <DatePicker label="Due Date" value={duedate} onChange={(date) => handleChangev10(date)} />
                </DemoContainer>
              </LocalizationProvider>
            </div>

          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', marginTop: '25px', flexWrap: 'wrap' }}>
            <div>
              <div style={{ display: 'flex', gap: '70px' }}>
                <div>
                  <FormControl sx={{ mt: 1, width: '230px' }} >
                    <InputLabel id="demo-select-small-label">Recurring Invoice?
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={recurringinvoice}
                      label="Recurring Invoice?"
                      onChange={handleChangev3}
                    >
                      <MenuItem>None</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div>
                  <FormControl sx={{ mt: 1, width: '230px' }} >
                    <InputLabel id="demo-select-small-label">Discount Type

                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={discount}
                      label="Discount Type"
                      onChange={handleChangev4}
                    >
                      <MenuItem value={0}>No Discount</MenuItem>
                      <MenuItem value={'Before Tax'}>Before Tax</MenuItem>
                      <MenuItem value={'After Tax'}>After Tax</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div style={{ marginTop: '15px' }}>
                <InputLabel style={{ fontSize: '14px', fontWeight: 'bold', padding: '10px' }}>Admin Note</InputLabel>

                <TextField
                  style={{ textAlign: 'left', width: '530px' }} // You can adjust the 'width' value as needed
                  hintText="Message Field"
                  floatingLabelText="MultiLine and FloatingLabel"
                  multiline
                  rows={2}
                />
              </div>
            </div>

            <div style={{ marginLeft: "50px" }}>

              {user && user.currencylist ? (<InputLabel id="demo-single-name-label" style={{ fontSize: '14px', fontWeight: 'bold' }}>Currency: <b style={{ color: 'green' }}>{user.currencylist.name}</b></InputLabel>) : <InputLabel id="demo-single-name-label" style={{ fontSize: '14px', fontWeight: 'bold' }}>Currency:</InputLabel>}<br />
              <InputLabel id="demo-single-name-label" style={{ fontSize: '14px', fontWeight: 'bold' }}>Bill To:</InputLabel>
              {user ? (<InputLabel style={{ fontSize: '14px', fontWeight: 'bold', marginTop: '5px' }}>
                Name: <span style={{ color: 'blue' }}>{user.name}</span></InputLabel>) :
                <InputLabel style={{ fontSize: '14px', fontWeight: 'bold', marginTop: '5px' }}>Name: </InputLabel>}

              {user ? (<InputLabel style={{ fontSize: '14px', fontWeight: 'bold' }}>Email: <span style={{ color: 'blue' }}>{user.email}</span></InputLabel>) : (<InputLabel style={{ fontSize: '14px', fontWeight: 'bold' }}>Email:</InputLabel>)}
              {user ? (<InputLabel id="demo-single-name-label" style={{ fontSize: '14px', fontWeight: 'bold' }}>Phone: <span style={{ color: 'blue' }}>{user.phone}</span></InputLabel>) : (<InputLabel id="demo-single-name-label" style={{ fontSize: '14px', fontWeight: 'bold' }}>Phone:</InputLabel>)}<br />

              {user && user.billing_addresses
                ? (<InputLabel style={{ fontSize: '14px', fontWeight: 'bold' }}>Ship To: <br /><span style={{ color: 'blue' }}>{user.billing_addresses[0].line_1},{user.billing_addresses[0].line_2},{user.billing_addresses[0].city},{user.billing_addresses[0].state},{user.billing_addresses[0].zipcode},{user.billing_addresses[0].country}</span></InputLabel>) : (<InputLabel style={{ fontSize: '14px', fontWeight: 'bold' }}>Ship To:</InputLabel>)}


            </div>
          </div>
          <div style={{ marginTop: '25px', padding: '10px 0px' }}>
            <FormControl sx={{ mt: 1, width: '250px' }} >
              <InputLabel id="demo-select-small-label">Product/Services

              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={product}
                label="Product/Services"
                onChange={handleChangev5}
              >
                <MenuItem value={''}>Custom</MenuItem>
                {price.map((elem, index) => {
                  return (<MenuItem key={index} value={elem.name
                  }>{elem.name} - {elem.price}Rs</MenuItem>);
                })}
              </Select>
            </FormControl>
          </div>
          <div>
            <AppBar position="static" style={{ backgroundColor: 'rgb(50, 50, 59)' }}>
              <Toolbar
                sx={{
                  display: 'flex', justifyContent: 'space-around', borderRadius: '5px'
                }}
              >
                <Typography variant="h11">
                  Item
                </Typography>
                <Typography variant="h11">
                  Description
                </Typography>
                <Typography variant="h11">
                  Qty
                </Typography>
                <Typography variant="h11">
                  Rate
                </Typography>
                <Typography variant="h11">
                  Tax
                </Typography>
                <Typography variant="h11">
                  Amount
                </Typography>
              </Toolbar>
            </AppBar>
          </div>
          <div>
            <div><div style={{ display: 'flex', gap: '10px', marginTop: '30px' }}>
              <TextField
                style={{ textAlign: 'left', width: '200px' }} // You can adjust the 'width' value as needed
                hintText="Message Field"
                placeholder='Item description'
                value={input.itemName}
                onChange={handleChangev15}
                multiline
                rows={2}
              />
              <TextField
                style={{ textAlign: 'left', width: '200px' }} // You can adjust the 'width' value as needed
                hintText="Message Field"
                placeholder='Long description'
                value={input.itemDescription}
                onChange={handleChangev16}
                floatingLabelText="MultiLine and FloatingLabel"
                multiline
                rows={2}


              />
              <TextField
                style={{ width: '150px' }}
                value={input.quantity}
                onChange={handleChangev6}
              // You can add other props and styles here as needed
              />
              <TextField
                style={{ width: '150px' }}
                placeholder='Rate'
                value={input.rate}
                onChange={handleChangev7}
              // You can add other props and styles here as needed
              />
              <FormControl sx={{ width: '130px' }} >
                <Select
                  id="demo-select-small"
                  value={input.tax}
                  onChange={handleChangev8}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                >
                  <MenuItem value={0}>
                    <span>0%</span>
                  </MenuItem>
                  <MenuItem value={5}>5%</MenuItem>
                  <MenuItem value={10}>10%</MenuItem>
                  <MenuItem value={18}>18%</MenuItem>
                </Select>
              </FormControl>
              <Typography />
              <AddIcon
                onClick={addCard}
                style={{ color: 'green', fontSize: '40px', marginLeft: '45px', cursor: 'pointer' }}
              />


            </div>

              <div>

                {cards.map((card, index) => (
                  <InvoiceCard
                    key={`card-${index}`}
                    card={card}
                    onCardDataChange={(field, value) => handleCardDataChange(index, field, value)}
                    onDelete={() => deleteHandlerCard(index)}
                  />
                ))}
              </div>
            </div>
          </div>
          <div style={{ width: '45%', float: 'right' }}>
            <hr />
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <div style={{ display: 'flex', gap: '55px', padding: '5px 30px' }}>
                <Typography style={{ fontSize: '15px' }}>Sub Total:</Typography>
                {(totalRates).toFixed(2) ? <Typography variant="h11">{(totalRates).toFixed(2)}</Typography> : <Typography variant="h11">{totalRates}.00</Typography>}
              </div>
            </div>
            <hr />
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <div style={{ display: 'flex', gap: '55px', padding: '5px 30px' }}>
                <Typography style={{ fontSize: '15px' }} >TAX: </Typography>
                {(totalTax).toFixed(2) ? <Typography variant="h11">{(totalTax).toFixed(2)}</Typography> : <Typography variant="h11">{totalTax}.00</Typography>}
              </div>
            </div>
            <hr />
            {discount ? <><div>
              <div style={{ display: 'flex', justifyContent: 'space-around', padding: '5px 0px', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
                  <InputLabel style={{ fontWeight: 'bold', fontSize: '14px' }} >Discount:</InputLabel>
                  <FormControl sx={{ width: '220px' }} >
                    <Select
                      id="demo-select-small"
                      value={distype}
                      onChange={handleChangev14}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                      }}
                    >
                      <MenuItem value={'flat'}>Flat</MenuItem>
                      <MenuItem value={'percentage'}>Percentage</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {/* {discont ?<Typography variant="h11">{discont.toFixed(2)}</Typography>:<Typography variant="h11">{discont}.00</Typography>} */}
                <Typography variant="h11">-{discont}.00</Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <TextField
                  onChange={handleChangev13}
                  value={inputvalue} />
              </div>
            </div><hr /></> : null}


            <div style={{ display: 'flex', justifyContent: 'space-around', padding: '5px 0px', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <InputLabel style={{ fontWeight: 'bold', fontSize: '14px' }} >Adjustment:</InputLabel>
                <TextField
                  onChange={handleChangev11}
                  value={adjustment} />
              </div>
              {(adjustment).toFixed(2) ? <Typography variant="h11">{(adjustment).toFixed(2)}</Typography> : <Typography variant="h11">{adjustment}.00</Typography>}
            </div>
            <hr style={{ border: '0.5px dashed grey' }} />
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <div style={{ display: 'flex', gap: '55px', padding: '5px 25px' }}>
                <Typography style={{ fontSize: '15px', fontWeight: 'bold' }} >Total: </Typography>
                {((totalRates + totalTax + adjustment) - discont).toFixed(2) ? <Typography variant="h11">{((totalRates + totalTax + adjustment) - discont).toFixed(2)}</Typography> : <Typography variant="h11">{((totalRates + totalTax + adjustment) - discont)}.00</Typography>}

              </div>
            </div>
          </div>
          <br />
          <div style={{ clear: 'both' }}>
            <hr />
            <div style={{ marginTop: '50px', display: 'flex', gap: '15px' }}>
              <Typography variant='h4'>Invoice Notes</Typography>
              <button style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', border: 'none', background: 'none' }} onClick={addNotes}>``
                <AddIcon style={{ fontSize: '30px', color: 'green' }} />
                <Typography variant='h6' style={{ color: 'green' }}>Add Invoice Notes</Typography>
              </button>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              {notes.map((notes, index) => (
                <div>
                  <InvoiceNote
                    key={`note-${index}`}
                    note={notes}
                    onNoteDataChange={(field, value) => handleNoteDataChange(index, field, value)}
                    onDelete={() => deleteHandlerNote(index)}
                  />
                  <hr />
                </div>

              ))}


            </div>

          </div>
          <div style={{ marginTop: '30px', float: 'right' }}>
            <button style={{ padding: '7px', display: 'flex', alignItems: 'center', gap: '6px', background: 'green', color: 'white', border: 'none', borderRadius: '3px' }}>SAVE INVOICE<ArrowForwardIcon style={{ fontSize: '20px' }} /></button>
          </div>







        </div>
      </Box>
    </div>
  )
}

export default AddInvoice