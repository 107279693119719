import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'


const ClientDetails = () => {
    const [client, setClient] = useState(null);

    const { id } = useParams();

    useEffect(() => {
        clientdetails();
    }, [])
     
    const clientdetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/client/getclient/${id}`);
            if (response.status === 200) {
                console.log(response);
                setClient(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching client details:', error);
        }
    };

    return (
        <div className='d-flex ml-5'>
            {client && (
                <div className='d-flex'>
                    <div>
                        <span>Name:</span><br />
                        <span>Email:</span><br />
                        <span>Company:</span><br />
                        <span>BusinessType:</span><br />
                        <span>Contact:</span>
                    </div>
                    <div className='ml-2'>
                        <span>{client.name}</span><br />
                        <span>{client.email}</span><br />
                        <span>{client.company}</span><br />
                        <span>{client.business_type}</span><br />
                        <span>{client.phone}</span>
                    </div>
                </div>
            )}
            {client && client.billing_addresses && client.billing_addresses.length > 0 && (
                <div className='d-flex ml-5'>
                    <div>
                        <span>FirstName</span><br />
                        <span>LastName</span><br />
                        <span>AddressLine1:</span><br />
                        <span>AddressLine2:</span><br />
                        <span>City:</span><br />
                        <span>State:</span><br />
                        <span>Country:</span><br />
                        <span>Zipcode:</span>
                    </div>
                    <div className='ml-2'>
                        <span>{client.billing_addresses[0].first_name}</span><br />
                        <span>{client.billing_addresses[0].last_name}</span><br />
                        <span>{client.billing_addresses[0].line_1}</span><br />
                        <span>{client.billing_addresses[0].line_2}</span><br />
                        <span>{client.billing_addresses[0].city}</span><br />
                        <span>{client.billing_addresses[0].state}</span><br />
                        <span>{client.billing_addresses[0].country}</span><br />
                        <span>{client.billing_addresses[0].zipcode}</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ClientDetails;
