


export default function SettingProfile() {


    return(

        <>
           <div className="container px-4">
                <div>
                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <div><h5 style={{ fontWeight: "bold", fontFamily: "sans-serif" }}>Setting / Profile Info</h5></div>

                        <div >
                            <span>
                                <button type="button" className="text-white btn border border-primary" style={{background:"#19c3e6"}}
                                >CREATE PROFILE +</button>
                            </span>

                        </div>
                    </div> 


                    <div className='row mt-4'>

                        <div className='col-md-6'>

                            <div className='form-group'>
                                <label for="clientName1" className="form-label ">First Name</label>
                                <input type="text" className="form-control border border-primary" id="clientName1 "  style={{background:"#D0F0FF"}}/>
                            </div>

                            <div className='form-group '>
                                <label for="clientName2" className="form-label">User Id</label>
                                <input type="text" className="form-control  border border-primary" id="clientName2"  style={{background:"#D0F0FF"}}/>
                            </div>


                        </div>

                        <div className='col-md-6'>

                            <div className='form-group  '>
                                <label for="clientName3" className="form-label">Last Name</label>
                                <input type="text" className="form-control  border border-primary" id="clientName3"  style={{background:"#D0F0FF"}} />
                            </div>
                            <div className='form-group'>
                                <label for="type1" className="form-label">Email</label>
                                <input type="text" className="form-control  border border-primary" id="type1"  style={{background:"#D0F0FF"}}/>
                            </div>


                            

                        </div>

                        <div className='col-md-12 mt-2' >
                            <div class="form-group ">
                                <label for="exampleFormControlTextarea1">Company details</label>
                                <textarea className="form-control mt-2  border border-primary"  style={{background:"#D0F0FF"}} id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>

                            <div  className='d-flex justify-content-end mt-4'>
                                <span>
                                    <button type="button" className="btn border border-primary"
                                    >CANCEL</button>
                                </span>


                                <span style={{ marginLeft: "20px" }}>
                                    <button type="button" className="btn  text-white" style={{background:"#19c3e6"}} >SAVE</button>
                                </span>
                            </div>
                        </div>



                    </div>


                </div>







            </div>
        </>
    )
}