import { TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
// import "../Styles/Login.css";
import axios from "axios";

const initialErrors = { email: false, password: false };

function Login() {
  const [ShowPwd, setShowPwd] = useState(false);
  const navigate = useNavigate()
  const [Value, setValue] = useState({
    email: "",
    password: "",
  });

  function handleChange(e) {
    setValue({ ...Value, [e.target.name]: e.target.value });
  }

  function handleSignIn(e) {
    e.preventDefault();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!Value.email.trim() || !Value.password.trim()) {
      Swal.fire({
        title: "Error!",
        text: "Fill all the required fields!",
        icon: "error",
        confirmButtonColor: "#03565A"
      });
      return;
    }

    if (!emailPattern.test(Value.email)) {
      Swal.fire({
        title: "Error!",
        text: "Please enter a valid email address",
        icon: "error",
        confirmButtonColor: "#03565A"
      });
      return;
    }
    let fd = {
      email: Value.email,
      password: Value.password,
    };
    axios
      .post(`${process.env.REACT_APP_WEB_URL}api/v1/auth/login`, fd)
      .then((res) => {
        localStorage.setItem("auth_token", res.data.token);
        localStorage.setItem("user_id", res.data.user._id);
        navigate('/home');
      })
      .catch((err) => {
        console.log(err.response, "sign in error");
        Swal.fire({
          title: "Error",
          text: err.response.data.error,
          icon: "error",
          confirmButtonColor: "#03565A"
        });
      });
  }

  return (
    <>


          <div className=" d-flex  justify-content-center align-items-center" style={{marginTop:"120px"}}>
           
            <div>
              {/* <img
                src={sigin}
                alt="Logo"
                style={{ width: "150px", height: "auto" }}
              /> */}
              <h5 style={{ fontSize: "25px", fontWeight: "500" }}> Invorail Sign In</h5>

              <div className="d-flex flex-column mt-4">
                <p style={{ fontSize: "20px" }} className="text-secondary">Admin Email Id</p>
                <TextField
                  sx={{
                    width: 500,
                    maxWidth: '100%',
                  }}
                  placeholder="Enter email id"
                  value={Value.email}
                  name="email"
                  onChange={handleChange}
                />
              </div>

              <div className="d-flex flex-column mt-4">
                <p style={{ fontSize: "20px" }} className="text-secondary">Admin Password</p>
                <TextField
                  sx={{
                    width: 500,
                    maxWidth: '100%',
                  }}
                  type="password"
                  placeholder="Enter password"
                  value={Value.password}
                  name="password"
                  onChange={handleChange}
                />

              </div>

              <div className="d-flex flex-column mt-4 align-items-center">
                <button
                  style={{
                    width: "100%",
                    padding: "20px 5px",
                    fontSize: "19px",
                    backgroundColor: "#03565A",
                    border: "none",
                    borderRadius: "4px",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={handleSignIn}
                >
                  Sign In
                </button>
              </div>
              <div className="mt-2">
            <Typography>Dont have  an account?<span style={{color:'blue',cursor:'pointer'}} onClick={()=>navigate('/signup')}>SignUp</span></Typography>
              </div>


            </div>
          </div>


    </>
  );
}

export default Login;
