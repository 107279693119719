import HomePage from "../Component/Pages/Dashboard/HomePage";
import Login from "../AuthPages/Login";

import InvoicePage from "../Component/Pages/Invoice/InvoicePage";
import ClientPage from "../Component/Pages/Client/ClientPage";
import LayoutDesign from "../Component/Layout/LayoutDesign";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "../Component/Navbar";
import BalancePage from "../Component/Pages/Balance/BalancePage";
import BalancePayment from "../Component/Pages/Balance/BalancePayment";
import CreateClient from "../Component/Pages/Client/CreateClient";
import ProductPage from "../Component/Pages/ProductServices/Product-Services";
import CreateInvoice from "../Component/Pages/Invoice/CreateInvoicePage";
import RecuringInvoices from "../Component/Pages/Invoice/Recurring Invoices";
import SettingProfile from "../Component/Setting/SettingProfileInfo";
import SettingUserRoles from "../Component/Setting/SettingUserRoles";
import ProductSale from "../Component/Pages/ProductServices/ProductTable";
import ClientDetails from "../Component/Pages/Client/ClientDetails";
import { useRoutes } from "react-router-dom";
import SignUp from "../AuthPages/Signup";
import token from "../AuthPages/Token";
import { Navigate } from "react-router-dom";
import AddInvoice from "../Component/Pages/Invoice/CreateInvoice2.js";




export default function InvorailRoute() {
    let routes = useRoutes([ 
        {
            path: '/',
            element: token() ? <LayoutDesign /> : <Navigate to={'/login'} />,
            // element: <Login/>,
            // element: <LayoutDesign />,

            children: [
                { path: '/home', element: <HomePage /> },
                { path: 'invoice', element: <InvoicePage /> },
                { path: 'create-invoice', element: <AddInvoice /> },
                { path: 'recurring-invoices', element: <RecuringInvoices /> },
                { path: "client", element: <ClientPage /> },
                { path: "add-client", element: <CreateClient /> },
                { path: 'products-services', element: <ProductPage /> },
                { path: 'products-sale', element: <ProductSale /> },
                { path: 'balance-info', element: <BalancePage /> },
                { path: "balance-payment", element: <BalancePayment /> },
                { path: "SettingProfileInfo", element: <SettingProfile /> },
                // {path:"deatailspage/:id",element:<DeatailsPage/>},
                // {path:"support-newticket",element:<Newticket/>},
                { path: "settinguser", element: <SettingUserRoles /> },
                { path: "clientdetailes/:id", element: <ClientDetails /> }

            ]
        },
        { path: 'login', element: <Login /> },
        { path: 'signup', element: <SignUp /> },
    ])
    return routes;
}

