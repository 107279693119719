import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { useNavigate } from 'react-router-dom';
import { HiFilter } from "react-icons/hi";
import axios from 'axios';
import { useEffect,useState } from 'react';
import swal from 'sweetalert';


function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('1', 159, "reaceive", 24, 4.0),
    createData('1', 237, "reaceive", 37, 4.3),
    createData('1', 262, "reaceive", 24, 6.0),
    createData('1', 305, "reaceive", 67, 4.3),
    createData('1', 356, "reaceive", 49, 3.9),
];


export default function ClientPage() {

    const navigate = useNavigate()

    const [clients,setClients] = useState([]);
    const token = localStorage.getItem('auth_token');

    useEffect(()=>{
        getClient()
    },[])

    const getClient = async()=>{
        try {
            const data = await axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/client/getclient`,{
                headers: {
                  'auth-token': token
                }
              });
            console.log(data);
            setClients(data.data.data);
            
        } catch (error) {
            
        }
    }

    const handleDeleteClient = async(id)=>{
          try {
            const data = await axios.delete(`${process.env.REACT_APP_WEB_URL}api/v1/client/deleteclient?id=${id}`)
            if(data.status === 200){
                swal('Client deleted successfully')
                getClient();
             }
            
          } catch (error) {
            swal('',error,"danger")
          }
    }

    return (
        <>
            <div className="container px-4">
                <div>
                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <div><h5 style={{ fontWeight: "bold", fontFamily: "sans-serif" }}>Clients</h5></div>
                        <div>
                            <button type="button" className="btn text-white " style={{ background: "#19c3e6" }} onClick={() => navigate(`/add-client`)}
                            > Add Clients</button>
                        </div>




                    </div>
                </div>
                <div>

                    <Card className='mt-4' style={{ border: "2px solid #01ACFF" }}>
                    <div className='d-flex flex-wrap align-items-center mt-2 ' style={{gap:"63%"}}>
                            <div><h6 style={{ fontWeight: "bold", fontFamily: "sans-serif", marginTop: "10px", marginLeft: "10px" }}>Invoices</h6></div>
                            <div >
                                <button type="button" className="btn border text-white" style={{ background: "#8BBDC7" }}>Paid</button>
                                <button type="button" className="btn   ml-4" style={{color:"#01ACFF"}} >Unpaid</button>
                                
                                 <HiFilter className='ml-4'/>
                            </div>
                        </div>

                        <div className='maintable'>

                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='left'>ID</TableCell>
                                        <TableCell align="left">Name</TableCell>
                                        <TableCell align="left">Email</TableCell>
                                        <TableCell align="left">Company</TableCell>
                                        <TableCell align="left">Business Type</TableCell>
                                        <TableCell align="left">Contact No</TableCell>
                                        <TableCell align="left">Actions</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {clients.map((row,index) => (
                                        <TableRow
                                            key={row._id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {index+1}
                                            </TableCell>
                                            <TableCell align="left">{row.name}</TableCell>
                                            <TableCell align="left">{row.email}</TableCell>
                                            <TableCell align="left">{row.company}</TableCell>
                                            <TableCell align="left">{row.business_type}</TableCell>
                                            <TableCell align="left">{row.phone}</TableCell>
                                            <TableCell align="left" >
                                                <span onClick={()=>navigate(`/clientdetailes/${row._id}`)}><RemoveRedEyeIcon /></span>
                                                <span onClick={()=>handleDeleteClient(row._id)}><DeleteIcon /></span>
                                                <span><SimCardDownloadIcon /></span>


                                            </TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                        </div>

                    </Card>
                </div>

            </div>
        </>
    )
}