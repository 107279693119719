import React from 'react'
import Sidebar from '../Sidebar'
import { Outlet } from 'react-router-dom'
import Header from '../Navbar'


const LayoutDesign = () => {
  return (

    <div >
   <div style={{ display: 'flex'}}>
        <div>
        <Sidebar />
        </div>
        <div  style={{width:"100%"}}>
          <div>
          <Header />
          </div>
          <div> <Outlet /></div>
         
        </div>

      </div>
    </div>
  )
}

export default LayoutDesign