import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  Typography,
  TextareaAutosize,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';


const InvoiceCard = ({ card, onDelete, onCardDataChange }) => {

  const handleChange = (fieldName, value) => {
    // Call the parent's function to update the card data
    onCardDataChange(fieldName, value);
  };


  const [amount, setAmount] = useState(0);
  useEffect(() => {
    const newAmount = card.rate * card.quantity;
    setAmount(newAmount);

    // Update the parent component with the new 'amount'
    onCardDataChange('amount', newAmount);
  }, [card.rate, card.quantity]);


  const [discnt, setDiscnt] = useState(0);
  useEffect(() => {
    const newDiscount = (card.rate * card.tax) / 100;
    setDiscnt(newDiscount);

    onCardDataChange('discnt', newDiscount);
  }, [card.rate, card.tax]);



  return (
    <>
      <hr />
      <div style={{ display: 'flex', gap: '10px', marginTop: '15px', alignItems: 'center' }}>
        <TextField
          style={{ textAlign: 'left', width: '200px' }} // You can adjust the 'width' value as needed
          hintText="Message Field"
          placeholder='Item description'
          value={card.itemName}
          onChange={(e) => handleChange('itemName', e.target.value)}
          floatingLabelText="MultiLine and FloatingLabel"
          multiline
          rows={2}
        />
        <TextField
          style={{ textAlign: 'left', width: '200px' }} // You can adjust the 'width' value as needed
          hintText="Message Field"
          placeholder='Item description'
          value={card.itemDescription}
          onChange={(e) => handleChange('itemDescription', e.target.value)}
          floatingLabelText="MultiLine and FloatingLabel"
          multiline
          rows={2}
        />
        <TextField
          style={{ width: '150px' }}
          value={card.quantity}
          onChange={(e) => handleChange('quantity', e.target.value)}

        // onChange={quantityHandler}

        // You can add other props and styles here as needed
        />
        <TextField
          style={{ width: '150px' }}
          value={card.rate}
          onChange={(e) => handleChange('rate', e.target.value)}
          placeholder='Rate'
        // You can add other props and styles here as needed
        />

        <FormControl sx={{ width: '130px' }} >
          <Select
            id="demo-select-small"
            value={card.tax}
            onChange={(e) => handleChange('tax', e.target.value)}
          >
            <MenuItem value={0}>
              <span>0%</span>
            </MenuItem>
            <MenuItem value={5}>5%</MenuItem>
            <MenuItem value={10}>10%</MenuItem>
            <MenuItem value={18}>18%</MenuItem>
          </Select>
        </FormControl>
        <div style={{marginLeft:'100px',display:'flex'}}>
        {
          amount.toFixed(2) ? <Typography>{amount.toFixed(2)}</Typography> : <Typography>{amount}.00</Typography>
        }

        <DeleteIcon onClick={onDelete}
          style={{ color: 'red', fontSize: '40px', marginLeft: '15px', cursor: 'pointer' }}
        />
        </div>
        {/* <Typography>
                   {discnt}.000
                </Typography> */}
      </div>

    </>
  );
};

export default InvoiceCard;