import Navbar from "./Component/Navbar";
// import Sidebar from "./Component/Sidebar";
import InvorailROute from "./Routes/Route";
import InvorailRoute from "./Routes/Route";
import { BrowserRouter, Routes } from 'react-router-dom';


function App() {
  return (
    <div>
      <BrowserRouter>
       <InvorailRoute/>
      </BrowserRouter>
      
    </div>


  );
}

export default App;
