import { InputLabel, TextField, TextareaAutosize, Box, Checkbox } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { set } from 'lodash';



function InvoiceNote({ note, setNotes, onDelete,onNoteDataChange }) {
  const [checked, setChecked] = useState(false);
  const [checkednote, setCheckednote] = useState(false)

  const handleChange = (fieldName, value) => {
    // Call the parent's function to update the card data
    onNoteDataChange(fieldName, value);
  };


  const handleLabelClick = () => {
    setChecked(!checked);

  };

  const handleNoteClick = () => {
    setCheckednote(!checkednote)
  }
  
  const deleteHandler = () => {
    onDelete(); 
    console.log('Delete action triggered with proper onDelete function');
    // This invokes the onDelete function passed from the ParentComponent
  };

  return (
    <>

      <div style={{ width: '50%', marginBottom: '40px' }}>
        <Box >
          <div>
            <InputLabel style={{ fontSize: '15px', fontWeight: 'bold', padding: '10px 5px' }}>Title</InputLabel>
            <TextField
              style={{ width: '470px' }}
              value={note.title}
              onChange={(e) => handleChange('title', e.target.value)}
              InputProps={{
                style: {
                  padding: '2px 3px',
                  height: '30px',
                }
              }}
            />
          </div>
          <div>
            <InputLabel style={{ fontSize: '15px', fontWeight: 'bold', padding: '10px 5px' }}>Description</InputLabel>
            <TextareaAutosize style={{ width: '470px', height: '80px' }} 
              value={note.description} onChange={(e) => handleChange('description',e.target.value)}/>
          </div>
          <div style={{ display: 'flex', gap: '35px', alignItems: 'center' }}>
            <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              {checked ? (
                <CheckIcon
                  color="primary"
                  style={{ cursor: 'pointer', width: '35px', height: '35px', transform: 'scale(1)', transition: 'transform 3s' }}
                  onClick={handleLabelClick}
                />
              ) : (
                <Checkbox
                  id="new"
                  checked={checked}
                  color="primary"
                  onChange={handleLabelClick}
                  style={{ cursor: 'pointer', width: '35px', height: '35px' }}
                />
              )}
              <InputLabel onClick={handleLabelClick} style={{ fontSize: '14px', cursor: 'pointer', fontWeight: 'bold' }}>
                Show to Customer
              </InputLabel>
            </div>
            <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              {checkednote ? (
                <CheckIcon
                  color="primary"
                  style={{ cursor: 'pointer', width: '35px', height: '35px', transform: 'scale(1)', transition: 'transform 3s' }}
                  onClick={handleNoteClick}
                />
              ) : (
                <Checkbox
                  id="new"
                  checked={checkednote}
                  color="primary"
                  onChange={handleNoteClick}
                  style={{ cursor: 'pointer', width: '35px', height: '35px' }}

                />
              )}
              <InputLabel onClick={handleNoteClick} style={{ fontSize: '14px', cursor: 'pointer', fontWeight: 'bold' }}>
                Save this note
              </InputLabel>
            </div>
            <div style={{ display: 'flex', gap: '2px', alignItems: 'center', color: 'red' }}>
              <DeleteIcon onClick={deleteHandler} />
              <InputLabel style={{ fontSize: '14px', cursor: 'pointer', fontWeight: 'bold', color: 'red' }}
                onClick={deleteHandler}>Delete</InputLabel>
            </div>
          </div>
        </Box>
      </div>
    </>
  )
}

export default InvoiceNote